
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";

.description {
    &::before {
        content: "";
        left: 50%;
        width: 100vw;
        transform: translateX(-50%);
        height: 100%;
        position: absolute;
        background-color: #000;
        z-index: -1;
    }
}
